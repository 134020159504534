import countUp from "@/views/my-components/widget/countUp.vue";
export default {
  name: "card-radial",
  components: {
    countUp
  },
  props: {
    id: {
      type: String,
      default: "countup"
    },
    title: {
      type: String,
      default: "-"
    },
    value: {
      type: Number,
      default: 0
    },
    decimalPlaces: {
      type: Number,
      default: 0
    },
    prefix: String,
    suffix: String,
    status: {
      type: String,
      default: "up"
    },
    compare: String,
    since: {
      type: String,
      default: "与上周相比"
    }
  },
  data() {
    return {
      series: [60],
      chartOptions: {
        colors: ["#5b73e8"],
        chart: {
          sparkline: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%"
            },
            track: {
              margin: 0
            },
            dataLabels: {
              show: false
            }
          }
        }
      }
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};